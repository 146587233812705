*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select {
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit, ::-webkit-datetime-edit-year-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute-field, ::-webkit-datetime-edit-second-field, ::-webkit-datetime-edit-millisecond-field, ::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  -webkit-print-color-adjust: unset;
  color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.relative {
  position: relative;
}

.m-0 {
  margin: 0;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .25rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-6 {
  height: 1.5rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.gap-4 {
  gap: 1rem;
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.p-0 {
  padding: 0;
}

.text-left {
  text-align: left;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.landing {
  width: 100%;
  z-index: -1;
  background-image: url("landing-md.a9a79771.jpg");
  background-size: cover;
  padding: 40px 0 135px 18px;
  position: relative;
}

.text-style-1 {
  width: 183px;
  height: 72px;
  letter-spacing: normal;
  text-align: left;
  color: #276377;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  line-height: 1.2;
  display: block;
}

.Talk-with-a-licensed-professional-therapist-online {
  width: 183px;
  height: 30px;
  letter-spacing: normal;
  text-align: left;
  color: #276377;
  margin: 7px 0 42px;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.21;
  display: block;
}

.landing-button {
  width: 182px;
  height: 40px;
  background-color: #276377;
  border: 1px solid #fff;
  border-radius: 40px;
  margin: 150px 80px -80px 0;
  padding: 9px 47px 10px 44px;
}

.menu {
  display: none;
}

.ham-menu {
  display: block;
}

.menu-item {
  color: #124455;
  margin: 0 0 0 24px;
}

.logo {
  width: 140px;
  height: 100.2px;
  margin: 0 180px 0 0;
  padding: 1.3px 0 0 1.3px;
}

.shadow-bckg {
  width: 100%;
  box-shadow: inset 0 12px 6px #00000029;
}

.start-button {
  width: 91px;
  height: 21px;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  font-family: Lato;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.24;
}

.teams {
  width: 100%;
  background-color: #f6f3fe;
  padding: 30px 24px 105px 23px;
}

.team-label {
  margin: 0 9.5px 20px 1.5px;
}

.teams-content {
  width: 327px;
  height: 104px;
  letter-spacing: normal;
  text-align: left;
  color: #124455;
  margin: 20px 0 0 1px;
  font-family: Lato;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.3;
}

.teams-button {
  width: 165px;
  height: 48px;
  background-color: #276377;
  border: 1px solid #fff;
  border-radius: 40px;
  margin: 0 0 50px;
  padding: 12px 29px 12px 28px;
}

.join-button {
  width: 111px;
  height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.2;
  display: block;
}

.cards {
  width: 100%;
  margin: 30px 0 0;
}

.card {
  width: 90%;
  height: 434px;
  background-color: #fff;
  border-radius: 13px;
  flex: 1 0 21%;
  margin: 30px 0 0;
}

.card-content {
  height: 274px;
  background-color: #f2f2f2;
  border-radius: 13px;
  margin: 10px 5px 34.5px;
}

.coach-name {
  height: 30px;
  letter-spacing: normal;
  text-align: center;
  color: #124455;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.36;
}

.coach-description {
  width: 200px;
  height: 47px;
  opacity: .6;
  letter-spacing: normal;
  text-align: center;
  color: #124455;
  margin: 4px 58px 0 59px;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.71;
}

.card-karim {
  background-image: url("karim.cd40809e.png");
}

.card-joumana {
  background-image: url("joumana.250e2796.png");
}

.card-rana {
  background-image: url("rana.70dd8985.jpg");
}

.card-alexa {
  background-image: url("alexa.22e273d6.jpg");
}

.journey {
  width: 100%;
  text-align: center;
  background-color: #f6f3fe;
  padding: 0 0 80px;
}

.story {
  letter-spacing: normal;
  text-align: center;
  color: #864dc5;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.35;
}

.how-it-works {
  letter-spacing: normal;
  color: #251230;
  margin: 10px 0 40px;
  font-family: Nunito;
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1;
}

.steps {
  display: none;
}

.get-matched {
  height: 44px;
  opacity: .6;
  letter-spacing: normal;
  text-align: center;
  color: #47225e;
  width: 90%;
  font-family: Rubik, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.35;
}

.step1 {
  width: 80%;
  height: 150px;
  background-color: #fff;
  border-radius: 10px;
  margin: 60px 40px 20px 20px;
  padding: 30px 25px 38px 20px;
}

.step-title {
  width: 97px;
  height: 43px;
  letter-spacing: normal;
  text-align: left;
  color: #47225e;
  margin: 0 303px 10px 0;
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.34;
}

.step-desc {
  width: 100%;
  height: 99px;
  opacity: .6;
  letter-spacing: normal;
  text-align: left;
  color: #47225e;
  margin: 10px 0 0;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.2;
}

.communicate {
  width: 80%;
  height: 140px;
  letter-spacing: normal;
  text-align: left;
  color: #276377;
  margin-left: 10%;
  font-family: Nunito;
  font-size: 25px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.34;
}

.step3text {
  margin-top: -12% !important;
}

.step2text {
  height: 43px;
  letter-spacing: normal;
  text-align: right;
  color: #864dc5;
  margin-top: 12%;
  margin-right: 55%;
  font-family: Nunito;
  font-size: 32px;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.34;
}

.step2card {
  width: 25%;
  height: 220px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: -8%;
  margin-left: 60%;
  padding: 30px 25px 38px 20px;
}

.card-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1024px) {
  .landing {
    width: 100%;
    height: 1280px;
    z-index: -1;
    background-image: url("middle-age-sad-serious-woman.ed9f4051.jpg");
    background-size: cover;
    padding: 294px 951px 395px 140px;
    position: relative;
  }

  .text-style-1 {
    letter-spacing: normal;
    text-align: left;
    color: #276377;
    font-family: Lato;
    font-size: 60px;
    font-style: normal;
    font-weight: 900;
    font-stretch: normal;
    line-height: 1.2;
    display: inline;
  }

  .Talk-with-a-licensed-professional-therapist-online {
    letter-spacing: normal;
    text-align: left;
    color: #276377;
    margin: 7px 0 42px;
    font-family: Lato;
    font-size: 34px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.21;
    display: inline;
  }

  .landing-button {
    width: 364px;
    height: 80px;
    background-color: #276377;
    border: 1px solid #fff;
    border-radius: 40px;
    margin: 32px 1415px 0 0;
    padding: 19px 88px;
  }

  .start-button {
    width: 188px;
    height: 42px;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.2;
  }

  .menu {
    margin: 90px 0 0;
    display: block;
  }

  .ham-menu {
    display: none;
  }

  .logo {
    width: 188px;
    height: 100.2px;
    margin: 0 67px 23.9px 140px;
    padding: 1.3px 0 0 1.3px;
  }

  .teams {
    width: 100%;
    height: 1003px;
    background-color: #f6f3fe;
    padding: 75px 951px 395px 140px;
  }

  .teams-content {
    width: 708px;
    height: 165px;
    letter-spacing: normal;
    text-align: left;
    color: #124455;
    margin: 0 0 0 24px;
    font-family: Nunito;
    font-size: 60px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 82px;
  }

  .teams-button {
    width: 364px;
    height: 80px;
    background-color: #276377;
    border: 1px solid #fff;
    border-radius: 40px;
    padding: 19px 70px;
    margin: 10px 0 0 10px !important;
  }

  .join-button {
    width: 200px;
    height: 42px;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-family: Lato;
    font-size: 35px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.2;
  }

  .cards {
    width: 100%;
    margin: 30px 0 0;
    display: flex;
  }

  .card {
    width: 100%;
    height: 434px;
    background-color: #fff;
    border-radius: 13px;
    flex: 1 0 21%;
    margin: 30px;
    padding: 35px 34px 9.5px 32px;
  }

  .card-content {
    width: 317px;
    height: 274px;
    background-color: #f2f2f2;
    border-radius: 13px;
    margin: 0 0 34.5px;
  }

  .coach-name {
    height: 30px;
    letter-spacing: normal;
    text-align: center;
    color: #124455;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.36;
  }

  .coach-description {
    width: 200px;
    height: 47px;
    opacity: .6;
    letter-spacing: normal;
    text-align: center;
    color: #124455;
    margin: 4px 58px 0 59px;
    font-family: Rubik;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.71;
  }

  .journey {
    width: 100%;
    text-align: center;
    background-color: #f6f3fe;
    padding: 0 0 2000px;
  }

  .mobile-steps {
    display: none;
  }

  .steps {
    display: block;
  }

  .story {
    letter-spacing: normal;
    text-align: center;
    color: #864dc5;
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.35;
  }

  .how-it-works {
    height: 82px;
    letter-spacing: normal;
    color: #251230;
    margin: 10px 0 40px;
    font-family: Nunito;
    font-size: 60px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.37;
  }

  .get-matched {
    height: 44px;
    opacity: .6;
    letter-spacing: normal;
    text-align: center;
    color: #47225e;
    font-family: Rubik, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.35;
  }

  .step1 {
    width: 445px;
    height: 220px;
    background-color: #fff;
    border-radius: 10px;
    margin: 84px 40.5px 40px 210px;
    padding: 30px 25px 38px 20px;
  }

  .step-title {
    width: 97px;
    height: 43px;
    letter-spacing: normal;
    text-align: left;
    color: #47225e;
    margin: 0 303px 10px 0;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.34;
  }

  .step-desc {
    width: 100%;
    height: 99px;
    opacity: .6;
    letter-spacing: normal;
    text-align: left;
    color: #47225e;
    margin: 10px 0 0;
    font-family: Rubik;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.2;
  }

  .divider {
    width: 2px;
    height: 1000px;
    background-color: #c9c2da;
    margin-top: -17%;
    margin-left: 50%;
  }

  .communicate {
    width: 347px;
    height: 87px;
    letter-spacing: normal;
    text-align: left;
    color: #ff648a;
    margin-top: -60%;
    margin-left: 60%;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.34;
  }

  .step3text {
    margin-top: -12% !important;
  }

  .step2text {
    height: 43px;
    letter-spacing: normal;
    text-align: left;
    color: #864dc5;
    margin-top: 12%;
    margin-left: 15%;
    margin-right: 55%;
    font-family: Nunito;
    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.34;
  }

  .step2card {
    width: 25%;
    height: 220px;
    background-color: #fff;
    border-radius: 10px;
    margin-top: -8%;
    margin-left: 60%;
    padding: 30px 25px 38px 20px;
  }

  .card-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.hover\:uppercase:hover {
  text-transform: uppercase;
}

@media (min-width: 1024px) {
  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }
}

/*# sourceMappingURL=index.74d743a6.css.map */
