@tailwind base;
@tailwind components;
@tailwind utilities;

.landing {
  background-image: url(../images/landing-md.jpg);
  background-size: cover;
  width: 100%;
  padding: 40px 0px 135px 18px;
  z-index: -1;
  position: relative;
}
.text-style-1 {
  display: block;
  width: 183px;
  height: 72px;
  font-family: Lato;
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #276377;
}
.Talk-with-a-licensed-professional-therapist-online {
  display: block;
  width: 183px;
  height: 30px;
  margin: 7px 0 42px;
  font-family: Lato;
  font-size: 25px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #276377;
}
.landing-button {
  width: 182px;
  height: 40px;
  margin: 150px 80px -80px 0;
  padding: 9px 47px 10px 44px;
  border-radius: 40px;
  border: solid 1px #fff;
  background-color: #276377;
}
.menu {
  display: none;
}
.ham-menu {
  display: block;
}
.menu-item {
  margin: 0 0 0 24px;
  color: #124455;
}
.logo {
  width: 140px;
  height: 100.2px;
  margin: 0 180px 0px 0px;
  padding: 1.3px 0 0 1.3px;
}
.shadow-bckg {
  width: 100%;
  box-shadow: inset 0 12px 6px 0 rgba(0, 0, 0, 0.16);
}

.start-button {
  width: 91px;
  height: 21px;
  font-family: Lato;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.teams {
  width: 100%;
  padding: 30px 24px 105px 23px;
  background-color: #f6f3fe;
}
.team-label {
  margin: 0 9.5px 20px 1.5px;
}
.teams-content {
  width: 327px;
  height: 104px;
  margin: 20px 0 0 1px;
  font-family: Lato;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #124455;
}

.teams-button {
  width: 165px;
  height: 48px;
  margin: 0 0 50px 0;
  padding: 12px 29px 12px 28px;
  border-radius: 40px;
  border: solid 1px #fff;
  background-color: #276377;
}

.join-button {
  display: block;
  width: 111px;
  height: 24px;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.cards {
  width: 100%;
  margin: 30px 0 0 0;
}
.card {
  width: 90%;
  height: 434px;
  margin: 30px 0 0 0;
  border-radius: 13px;
  background-color: #fff;
  flex: 1 0 21%;
}
.card-content {
  height: 274px;
  margin: 10px 5px 34.5px;
  border-radius: 13px;
  background-color: #f2f2f2;
}
.coach-name {
  height: 30px;
  font-family: Nunito;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #124455;
}
.coach-description {
  width: 200px;
  height: 47px;
  margin: 4px 58px 0 59px;
  opacity: 0.6;
  font-family: Rubik;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: center;
  color: #124455;
}
.card-karim {
  background-image: url(../images/karim.png);
}
.card-joumana {
  background-image: url(../images/joumana.png);
}
.card-rana {
  background-image: url(../images/rana.jpg);
}
.card-alexa {
  background-image: url(../images/alexa.jpg);
}
.journey {
  width: 100%;
  background-color: #f6f3fe;
  text-align: center;
  padding: 0 0 80px 0;
}

.story {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #864dc5;
}
.how-it-works {
  font-family: Nunito;
  margin: 10px 0 40px 0;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #251230;
}
.steps {
  display: none;
}
.get-matched {
  height: 44px;
  opacity: 0.6;
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #47225e;
  width: 90%;
}

.step1 {
  width: 80%;
  height: 150px;
  margin: 60px 40px 20px 20px;
  padding: 30px 25px 38px 20px;
  border-radius: 10px;
  background-color: #fff;
}

.step-title {
  width: 97px;
  height: 43px;
  margin: 0 303px 10px 0;
  font-family: Nunito;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: left;
  color: #47225e;
}

.step-desc {
  width: 100%;
  height: 99px;
  margin: 10px 0 0;
  opacity: 0.6;
  font-family: Rubik;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #47225e;
}

.communicate {
  width: 80%;
  margin-left: 10%;
  height: 140px;
  font-family: Nunito;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: left;
  color: #276377;
}

.step3text {
  margin-top: -12% !important;
}

.step2text {
  margin-top: 12%;
  margin-right: 55%;
  height: 43px;
  font-family: Nunito;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: right;
  color: #864dc5;
}
.step2card {
  margin-top: -8%;
  margin-left: 60%;
  width: 25%;
  height: 220px;
  padding: 30px 25px 38px 20px;
  border-radius: 10px;
  background-color: #fff;
}

.card-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

@media (min-width: 1024px) {
  .landing {
    background-image: url(../images/middle-age-sad-serious-woman.jpg);
    background-size: cover;
    width: 100%;
    height: 1280;
    padding: 294px 951px 395px 140px;
    z-index: -1;
    position: relative;
  }
  .text-style-1 {
    font-family: Lato;
    font-size: 60px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #276377;
    display: inline;
  }
  .Talk-with-a-licensed-professional-therapist-online {
    margin: 7px 0 42px;
    font-family: Lato;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #276377;
    display: inline;
  }
  .landing-button {
    width: 364px;
    height: 80px;
    margin: 32px 1415px 0 0;
    padding: 19px 88px;
    border-radius: 40px;
    border: solid 1px #fff;
    background-color: #276377;
  }
  .start-button {
    width: 188px;
    height: 42px;
    font-family: Lato;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .menu {
    margin: 90px 0 0 0;
    display: block;
  }
  .ham-menu {
    display: none;
  }
  .logo {
    width: 188px;
    height: 100.2px;
    margin: 0 67px 23.9px 140px;
    padding: 1.3px 0 0 1.3px;
  }
  .teams {
    width: 100%;
    height: 1003px;
    padding: 75px 951px 395px 140px;
    background-color: #f6f3fe;
  }
  .teams-content {
    width: 708px;
    height: 165px;
    margin: 0 0 0 24px;
    font-family: Nunito;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 82px;
    letter-spacing: normal;
    text-align: left;
    color: #124455;
  }

  .teams-button {
    width: 364px;
    height: 80px;
    padding: 19px 70px;
    border-radius: 40px;
    border: solid 1px #fff;
    background-color: #276377;
    margin: 10px 0 0 10px !important;
  }

  .join-button {
    width: 200px;
    height: 42px;
    font-family: Lato;
    font-size: 35px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .cards {
    display: flex;
    width: 100%;
    margin: 30px 0 0 0;
  }
  .card {
    width: 100%;
    height: 434px;
    margin: 30px;
    padding: 35px 34px 9.5px 32px;
    border-radius: 13px;
    background-color: #fff;
    flex: 1 0 21%;
  }
  .card-content {
    width: 317px;
    height: 274px;
    margin: 0 0 34.5px;
    border-radius: 13px;
    background-color: #f2f2f2;
  }
  .coach-name {
    height: 30px;
    font-family: Nunito;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #124455;
  }
  .coach-description {
    width: 200px;
    height: 47px;
    margin: 4px 58px 0 59px;
    opacity: 0.6;
    font-family: Rubik;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #124455;
  }
  .journey {
    width: 100%;
    background-color: #f6f3fe;
    text-align: center;
    padding: 0 0 2000px 0;
  }
  .mobile-steps {
    display: none;
  }
  .steps {
    display: block;
  }

  .story {
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #864dc5;
  }
  .how-it-works {
    height: 82px;
    font-family: Nunito;
    margin: 10px 0 40px 0;
    font-size: 60px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    color: #251230;
  }

  .get-matched {
    height: 44px;
    opacity: 0.6;
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #47225e;
  }

  .step1 {
    width: 445px;
    height: 220px;
    margin: 84px 40.5px 40px 210px;
    padding: 30px 25px 38px 20px;
    border-radius: 10px;
    background-color: #fff;
  }

  .step-title {
    width: 97px;
    height: 43px;
    margin: 0 303px 10px 0;
    font-family: Nunito;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: left;
    color: #47225e;
  }

  .step-desc {
    width: 100%;
    height: 99px;
    margin: 10px 0 0;
    opacity: 0.6;
    font-family: Rubik;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #47225e;
  }

  .divider {
    width: 2px;
    height: 1000px;
    margin-left: 50%;
    margin-top: -17%;
    background-color: #c9c2da;
  }

  .communicate {
    width: 347px;
    height: 87px;
    margin-left: 60%;
    margin-top: -60%;
    font-family: Nunito;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: left;
    color: #ff648a;
  }

  .step3text {
    margin-top: -12% !important;
  }

  .step2text {
    margin-top: 12%;
    margin-right: 55%;
    height: 43px;
    font-family: Nunito;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: left;
    color: #864dc5;
    margin-left: 15%;
  }
  .step2card {
    margin-top: -8%;
    margin-left: 60%;
    width: 25%;
    height: 220px;
    padding: 30px 25px 38px 20px;
    border-radius: 10px;
    background-color: #fff;
  }

  .card-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
